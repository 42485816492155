import React from 'react';

export default function Keyword(props) {

    let keyword = props.keyword;
    let title   = `${keyword.phrase}\n${keyword.type || ''}\n${keyword.status || ''} - ${keyword.status_phrase || ''}`;
    let classes = `keyword status-${keyword.status} type-${keyword.type}`;

    return (
        <div className={classes} title={title} onClick={edit}>
            {keyword.phrase}
        </div>
    );

    function edit(e) {

        e.stopPropagation();

        props.editPhrase("keyword", props.keyword.phrase, props.customer);

    }
}

