import React, { useState, useEffect } from 'react';
import CustomFetch from '../../../CustomFetch';
import Modal, { ModalBody } from '../../Modal';
import * as $ from 'jquery';

require('bootstrap');

export default function CommentForm({customer, updateCustomer}) {

    let [comment, setComment] = useState('');
    let comments              = customer && customer.comments ? customer.comments : [];

    useEffect(() => setComment(''), [customer]);

    return (
        <Modal id="comment-form" title="Add comment">
            <ModalBody className="modal-body">
                <div className="form-group">
                    <br/>

                    <label>
                        Comment
                        <textarea value={comment} onChange={e => setComment(e.target.value)}
                                  className="form-control"/>
                    </label>

                    <div className="justify-content-end actions" style={{display: 'flex'}}>
                        <button type="button" className="btn btn-primary" onClick={saveComment}>
                            Save
                        </button>
                    </div>
                </div>
                <h5 className="modal-title">Old comments</h5>
                <br/>
                <div id="old_comments">
                    {comments.map((value, index) => (

                        <div className="comment-section" key={index}>
                            <div className="comment-info  customer-type">{value.time} {value.user_id}</div>
                            <div className="comment-text">
                                {value.comment}
                                <button className="fa fa-trash comment-delete" type="button"
                                        style={{float: 'right'}} onClick={() => deleteComment(index)} />
                            </div>
                        </div>
                    ))}
                </div>
                <br/>
            </ModalBody>
        </Modal>
    );

    /**
     * remove the comment from database
     */
    function deleteComment(id) {

        let data = {
            agency_id:   customer.agency_id,
            customer_id: customer._id,
            comment_id:  id,
        };

        CustomFetch.post('remove-comment', data).then(res => {

            updateCustomer(res.data);

        });

    }

    function saveComment(e) {

        e.stopPropagation();

        let data = {
            agency_id:   customer.agency_id,
            customer_id: customer._id,
            comment:     comment,
        };

        CustomFetch.post('set-comment', data).then(res => {

            setComment('');

            updateCustomer(res.data);
            $('#comment-form').modal('hide');
        });

    }

}
