import React  from 'react';
import Filter from './Filter';
import Sort from './Sort';
import YesNo from './YesNo';

export default function Filters(props) {

    const options = props.options;
    const counts  = props.counts;

    return (
        <div className="filter-box">

            <div className="row">
                <div className="col-lg-6">
                    <h4>Filters</h4>
                    <div className="btn-group">

                        <Filter title="Active" filter="active" options={options} counts={counts}
                                setOptions={props.setOptions}/>
                        <Filter title="Autopilot Off" filter="autopilot_off" options={options} counts={counts}
                                setOptions={props.setOptions}/>
                        <Filter title="Warnings" filter="warnings" options={options} counts={counts}
                                setOptions={props.setOptions}/>
                        <Filter title="Critical" filter="critical" options={options} counts={counts}
                                setOptions={props.setOptions}/>
                        <Filter title="Attention" filter="need_attention" options={options} counts={counts}
                                setOptions={props.setOptions}/>

                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="sorts">
                        <h4>Sorts</h4>
                        <div className="btn-group">
                            <Sort title="Costs" name="costs" options={options} setOptions={props.setOptions}/>
                            <Sort title="Last Okay" name="last_okayed_at" options={options}
                                  setOptions={props.setOptions}/>
                            <Sort title="Started" name="started_at" options={options} setOptions={props.setOptions}/>
                            <Sort title="Created" name="created_at" options={options} setOptions={props.setOptions}/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-1">
                    <YesNo title="Collapse" name="collapsed" value={props.collapsed} onChange={props.setCollapsed}/>
                </div>

                <div className="col-lg-1">
                    <YesNo title="Translate" name="translate" value={props.translate} onChange={props.setTranslate}/>
                </div>
                <div className="col-lg-1">
                    <YesNo title="Timeline" name="timeline" value={props.timeline} onChange={props.setTimeline}/>
                </div>
            </div>

            <div className="row">

                <div className="col-md-6">
                    <br/>
                    <input type="text" id="search" placeholder="Search Customer" value={options.search}
                           onChange={handleSearchChange}/>
                </div>

                <div className="col-md-6">
                    <br/>

                    <select id="agency-filter" value={options.agency_id} title="agency" name="agency"
                            onChange={handleAgencyChange}>
                        <option value="">All</option>
                        {props.agencies.map((agency) => (
                            <option key={agency.id} value={agency.id}>{agency.name}</option>))}
                    </select>
                </div>

            </div>

        </div>
    );

    function handleSearchChange(event) {

        let options    = {...props.options};
        options.search = event.target.value;

        props.setOptions(options);

    }

    function handleAgencyChange(event) {

        let options       = {...props.options};
        options.agency_id = event.target.value;

        props.setOptions(options);

    }

}