import 'regenerator-runtime/runtime';

import React, { useState, useEffect } from 'react';
import AppContext from '../../contexts/AppContext';
import GoogleAdsTable from './logs/GoogleAdsTable';
import ChangesTable from './logs/ChangesTable';
import moment from 'moment';

export default function Logs() {

    const {api} = React.useContext(AppContext);

    const [agencies, setAgencies]   = useState([]);
    const [customers, setCustomers] = useState([]);

    const [filters, setFilter] = useState({
        agencyId:   '',
        customerId: '',
        logType:    '',
        startDate:  moment().subtract(10, 'days').format('Y-M-D'),
        endDate:    '',
        onlyUsers:  false,
    });

    const [logs, setLogs] = useState({
        google_ads: [],
        changes:    [],
        stripe:     [],
        activity:   [],
    });

    useEffect(() => {
        if(agencies.length === 0) {
            loadAgencies();
        }
    });


    useEffect(() => {

        if (filters.agencyId) {

                let data = {
                    agency_id: filters.agencyId,
                    limit:     1000,
                    sort:      'name',
                    sort_dir:  1,
                };

                api.get('get-customers', data).then(res => setCustomers(res.data.customers));

        }
    }, [filters.agencyId, api]);

    return (

        <div id="logs" className="container">
            <h2>Logs</h2>
            <br/>

            <div className="filter-box">

                <select onChange={e => updateFilter({agencyId: e.target.value})}>
                    <option value="">All agencies</option>
                    {agencies.map((agency) => (
                        <option key={agency.id} value={agency.id}>{agency.name}</option>
                    ))}
                </select>

                <select id="customer_id" value={filters.customerId}
                        onChange={e => updateFilter({customerId: e.target.value})}>
                    <option>All customers</option>
                    {customers.map((customer) => (
                        <option key={customer._id} value={customer._id}>{customer.name}</option>
                    ))}
                </select>

                <select id="type" value={filters.logType} onChange={e => updateFilter({logType: e.target.value})}>
                    <option value="">Log type</option>
                    <option value="google_ads">Google ADS</option>
                    <option value="changes">Customer changes</option>
                </select>


                <input id="start-date" type="text" size="12" value={filters.startDate}
                       onChange={e => updateFilter({startDate: e.target.value})}/>
                <input id="end-date" type="text" size="12" value={filters.endDate}
                       onChange={e => updateFilter({endDate: e.target.value})}/>
                <input id="only_users" type="checkbox" title="Only show real users" value={filters.onlyUsers}
                       onChange={e => updateFilter({onlyUsers: e.target.value})}/>

                <button id="search-button" onClick={loadLogs}>Search</button>
            </div>

            <br/>

            <div id="table-container">

                {filters.logType === 'changes' && <ChangesTable rows={logs.changes}/>}
                {filters.logType === 'google_ads' && <GoogleAdsTable rows={logs.google_ads}/>}

            </div>

        </div>

    );

    function updateFilter(newFilter) {
        setFilter({...filters, ...newFilter});
    }

    function loadAgencies() {

        return api.get('get-agencies', {}).then(res => setAgencies(res.data));

    }

    function loadLogs() {

        if (!filters.customerId) {
            alert('You must select customer!');
            return;
        }

        if (!filters.logType) {

            return alert('Log type is not selected.');

        } else if (filters.logType === 'changes' && !filters.agencyId) {

            return alert('Agency should be selected for this log type.');

        }

        let data = {
            type:        filters.logType,
            agency_id:   filters.agencyId,
            customer_id: filters.customerId,
            start_date:  filters.startDate,
            end_date:    filters.endDate,
            only_users:  filters.onlyUsers ? 1 : 0,
        };

        return api.get('get-logs', data).then(res => {
            let updatedLogs = {...logs, [filters.logType]: res.data};
            setLogs(updatedLogs);
        });

    }

}
