class CustomFetch {

    static init(loading, auth) {

        this.apiUrl          = process.env.REACT_APP_API_URL;
        this.setLoadingState = loading;
        this.auth            = auth;

    }

    static get(service, params, sneaky = false) {

        let url     = this.generateUrl(service, params);
        let options = {method: 'GET'};

        return this.makeRequest(url, params, options, sneaky);

    }

    static post(service, params, sneaky = false) {

        let url = this.generateUrl(service);

        let options = {
            method:  'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body:    this.generateQueryString(params)
        };

        return this.makeRequest(url, params, options, sneaky);
    }

    static makeRequest(url, params, options, sneaky = false) {

        options.credentials = 'include';

        if(!sneaky) {

            if (this.requests) {
                this.requests++;
            } else {
                this.requests = 1;
            }

            this.setLoadingState(true);

        }


        return fetch(url, options)
            .then(res => {
				
                if (!res.ok) {
                    throw res.status;
                }
				
                this.auth && this.auth(true);

                if(!sneaky) {
                    this.requests--;
                }

                if (this.requests === 0) {
                    this.setLoadingState(false);
                }

                return res.json();

            })
            .catch(err => {

                if(!sneaky) {
                    this.requests--;
                }

                if (err === 403) {

                    this.auth && this.auth(false);
                    this.setLoadingState(false);

                } else {

                    console.log('Something went wrong');

                }
				
				return Promise.reject();

            });

    }

    static generateUrl(service, params = {}) {

        if (Object.keys(params).length) {
            return this.apiUrl + '/' + service + '?' + CustomFetch.generateQueryString(params);
        } else {
            return this.apiUrl + '/' + service;
        }

    }

    static generateQueryString(params) {

        return Object.keys(params).map(k => {
            if (params[k] instanceof Array) {
                return params[k].map((v) => encodeURIComponent(k) + '[]=' + encodeURIComponent(v)).join('&');
            } else {
                return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
            }
        }).join('&');

    }
}

export default CustomFetch;