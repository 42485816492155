import 'regenerator-runtime/runtime';

import React, { useState, useRef, useEffect } from 'react';
import AppContext from '../../contexts/AppContext';
import { GoogleCharts } from 'google-charts';

export default function Jobs() {

    const {api} = React.useContext(AppContext);

    const [jobs, setJobs]         = useState([]);
    const [jobStats, setJobStats] = useState([]);
    const [viewType, setViewType] = useState('table');
    const date                    = '2020-10-10';

    useEffect(() => {

        if (viewType === 'table' && jobs.length === 0) {

            loadJobs();

        } else if (viewType === 'graph' && jobStats.length === 0) {

            getJobStats();

        }

    });

    let chartElement = useRef();

    return (

        <div className="container-fluid">

            <div>
                <span style={{cursor: 'pointer', color: 'blue'}}
                      onClick={() => setViewType('table')}>Table</span>
                |
                <span style={{cursor: 'pointer', color: 'blue'}}
                      onClick={() => setViewType('graph')}>Graph</span>
            </div>


            {viewType === 'graph' && <JobGraph/>}
            {viewType === 'table' && <JobTable/>}


        </div>

    );

    function JobGraph() {
        return (
            <div ref={chartElement} id="job-stats" style={{width: '6000px', height: '800px'}}/>
        );
    }

    function JobTable() {
        return (
            <div>
                <h2>Jobs ran in {date}</h2>
                <table className="jobs table table-bordered">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Runs</th>
                        <th>Failed</th>
                        <th>Avg Duration in sec</th>
                        <th>Duration over 10 sec</th>
                        <th>Max Duration in sec</th>
                        <th>Avg memory in Mb</th>
                        <th>Peak memory in Mb</th>
                    </tr>
                    </thead>
                    <tbody>

                    {jobs.map((row, index) => (
                        <tr key={index}>
                            <td>{row.name}</td>
                            <td>{row.runs}</td>
                            <td>{row.runs - row.attempts}</td>
                            <td>{parseFloat((row.duration / 1000).toFixed(2))}s</td>
                            <td>{parseFloat(((row.duration_over_10 / row.runs) * 100).toFixed(2))}%</td>
                            <td>{parseFloat((row.max_duration / 1000).toFixed(2))}s</td>
                            <td>{parseFloat(row.memory.toFixed(2))}MB</td>
                            <td>{parseFloat(row.memory_peak.toFixed(2))}MB</td>
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>
        );
    }

    function loadJobs() {

        return api.get('get-jobs', {}).then(res => {

            let updatedJobs = [];
            Object.keys(res.data.jobs).forEach(key => {
                updatedJobs.push(res.data.jobs[key]);
            });

            updatedJobs.push(res.data.summary);

            setJobs(updatedJobs);
        });

    }

    function getJobStats() {

        let data = {date: date};

        return api.get('get-job-stats', data).then(res => {
            setJobStats(res.data);
            GoogleCharts.load(renderChart);
        });

    }

    function renderChart() {

        //var data = google.visualization.arrayToDataTable(jobStats);

        let data = new GoogleCharts.api.visualization.DataTable();

        data.addColumn('string', 'Time');
        data.addColumn('number', 'Count');
        data.addColumn('number', 'Fails');
        data.addColumn('number', 'Memory Peak');
        data.addColumn({type: 'string', role: 'tooltip'});
        data.addRows(jobStats);

        let view = new GoogleCharts.api.visualization.DataView(data);

        let options = {
            title:  'Jobs ' + date,
            width:  6000,
            height: 800,
            bar:    {groupWidth: '95%'},
            legend: {position: 'none'},
            series: {
                0: {targetAxisIndex: 0, color: 'black'},
                1: {targetAxisIndex: 0, color: 'red'},
                2: {targetAxisIndex: 0, color: 'orange'},
            },
            vAxes:  {
                0: {title: 'Count'},
                1: {title: 'Memory Peak'}
            }
        };

        let chart = new GoogleCharts.api.visualization.ColumnChart(chartElement.current);

        chart.draw(view, options);
    }

}
