import 'regenerator-runtime/runtime';

import React from 'react';
import AppContext from '../contexts/AppContext';
import { Link } from 'react-router-dom';

export default function Nav() {

    const {api} = React.useContext(AppContext);

    return (

        <div className="container-fluid">

            <nav className="navbar">

                <h2 title="Ryan Reynolds">Sentinel</h2>

                <button className="navbar-toggler toggler-example"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent1"
                        aria-controls="navbarSupportedContent1"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon">
                        <i className="fas fa-cheeseburger fa-1x"></i>
                    </span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent1">
                    <ul className="navbar-nav mr-auto">
                        <li><Link to="/overview"><strong>Overview</strong></Link></li>
                        <li><Link to="/customers">Customers</Link></li>
                        <li><Link to="/keywords">Keywords</Link></li>
                        <li><Link to="/transactions">Transactions</Link></li>
                        <li><Link to="/logs">Logs</Link></li>
                        <li><Link to="/jobs">Jobs</Link></li>
                        <li><Link to="/economy">Economy</Link></li>
                        <li><Link to="/billing">Billing</Link></li>
                        <li><Link to="/paused">Paused</Link></li>
                        <li><Link to="/report">Report</Link></li>

                        <li><a href={process.env.REACT_APP_API_URL + '/get-customer-csv'}>CSV</a></li>
                        <li><Link to="/logout" onClick={logout}>Logout</Link></li>
                    </ul>
                </div>


            </nav>
        </div>


    );

    function logout(e) {

        e.preventDefault();

        return api.get('logout', {}).then(() => {
            api.auth();
        });

    }

}