import React, { useEffect, useState } from 'react';

import Ad from './Ad';
import Keyword from './Keyword';
import SearchTerm from './SearchTerm';
import Timeline from './Timeline';
import Chart from './Chart';
import CustomFetch from '../../../CustomFetch';

export default function Customer(props) {

    let customer = props.customer;

    const lastOk         = customer.last_okayed_at + ' - ' + (customer.last_okayed_by ? customer.last_okayed_by : '') + (customer.is_changed ? ' (Changed)' : '');
    const ads            = customer.ads ? customer.ads : [];
    const keywords       = customer.all_keywords && customer.all_keywords.original ? customer.all_keywords.original : [];
    const searchTerms    = customer.search_terms ? customer.search_terms : [];
    const scheduleErrors = customer.schedule_errors instanceof Array ? {} : customer.schedule_errors;

    /**
     * Show the comments preview
     * @type {{}}
     */
    let commentStyle   = {};
    let commentPreview = 'No comments yet';

    if (customer.comments && customer.comments.length) {
        commentStyle   = {color: '#007bff'};
        commentPreview = customer.comments[customer.comments.length - 1].comment;
    }

    /**
     * Handles the collapse on customer level, get initial from global collapse state
     */
    const [collapsed, setCollapsed] = useState(props.collapsed);

    useEffect(() => {

        setCollapsed(props.collapsed);

    }, [props.collapsed]);

    return (

        <div key={customer._id} className={customer.enabled ? 'customer' : 'customer customer-paused'}
             onClick={(e) => e.ctrlKey ? props.reloadCustomer(customer) : handleCollapse()}>

            <div className="row stats">

                <Timeline className="col-lg-12 timeline" timeline={customer.timeline} options={props.options}/>

                <div className="col-lg-5">

                    <div style={{clear: 'both'}}>
                        <i className={customer.need_attention ?
                            'fa fa-clock attention_status need_attention' : 'fa fa-clock attention_status'}
                           title={lastOk} onClick={setOkay}/>
                        &nbsp;
                        <a className="customer-url" target="_blank" rel="noopener noreferrer"
                           href={customer.admin_url}>{customer.name.substr(0, 40)}</a>
                        &nbsp;
                        <i className="fa fa-envelope send-message" title="Send Message" onClick={showMessage}/>
                        &nbsp;
                        <i className="fa fa-comment add-comment" style={commentStyle} title={commentPreview}
                           onClick={showComments}/>
                        &nbsp;
                        <i className={'autopilot fa fa-plane' + (customer.autopilot ? ' on' : '')}
                           title="Turn on/off autopilot" onClick={toggleAutopilot}/>
                        &nbsp;
                        <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span title="Started At">{customer.started_at}</span>&nbsp;&nbsp;
                        <span className="agency-name">{customer.agency_id}</span> -
                        <span className="customer-type">
                            {customer.self_service ? 'Self-service' : 'Not self-service'}
                        </span>

                        &nbsp;
                    </div>
                </div>
                <div className="col-lg-5">
                    <Chart stats={customer.stats}/>
                </div>

                <div className="col-lg-2">
                    <div className="schedule">
                        {Object.keys(scheduleErrors).map(key => (

                            <div className="schedule-error badge badge-warning" key={key}
                                 title={scheduleErrors[key].text ? scheduleErrors[key].text : key}>
                                {key}
                            </div>
                        ))}
                    </div>
                </div>

            </div>
            <div className="row collapsed-view" style={{display: collapsed ? 'none' : 'flex'}}>
                <div className="col-lg-3">
                    <div>
                        <div className="title">Ads</div>
                        <div className="ads">
                            {ads.map((ad, index) => (<Ad ad={ad} key={index}/>))}
                        </div>
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="title">Keywords</div>
                    <div className="keywords">
                        {keywords.map((keyword, index) => (<Keyword keyword={keyword} customer={customer}
                                                                    key={index} editPhrase={props.editPhrase}/>))}
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="title">Search terms</div>
                    <div className="search-terms">
                        {searchTerms.map((searchTerm, index) => (
                            <SearchTerm searchTerm={searchTerm} customer={customer} key={index}
                                        editPhrase={props.editPhrase}/>))}
                    </div>
                </div>

                <div className="col-lg-2">
                    <div className="title">Geo</div>
                    <div className="geo-targets">
                        {customer.geo_targets.map((geoTarget, index) => (
                            <div className="geo-target" key={index}>{geoTarget}</div>
                        ))}
                    </div>
                </div>

                <div className="col-lg-3">
                    <strong/>

                    <br/>
                    <div className="alerts">
                        {customer.alerts.map(alert => (
                            <div className="badge badge-warning" title={alert.created_at} key={alert.alert_id}>
                                {alert.alert_id.toUpperCase().replace(/_/g, ' ')}
                            </div>
                        ))}
                    </div>
                    <div className="warnings">
                        {customer.warnings.map(warning => (
                            <div className="badge badge-danger" title={warning.message} key={warning.warning_id}>
                                {warning.warning_id.toUpperCase().replace(/_/g, ' ')}
                            </div>

                        ))}
                    </div>
                </div>
            </div>
        </div>
    );

    function handleCollapse() {
        setCollapsed(!collapsed);

    }

    function setOkay(event) {

        event.stopPropagation();

        let params = {
            agency_id:   customer.agency_id,
            customer_id: customer._id
        };

        CustomFetch.get('set-okay', params).then(res => {

            props.updateCustomer({...customer, need_attention: false});
            props.reloadCustomer(customer, true);

        });

    }

    function toggleAutopilot(event) {

        event.stopPropagation();

        let params = {
            agency_id:   customer.agency_id,
            customer_id: customer._id,
            autopilot:   customer.autopilot ? 0 : 1
        };

        CustomFetch.get('set-autopilot', params).then(res => {
            props.updateCustomer(res.data);
        });

    }

    function showComments(e) {
        e.stopPropagation();
        props.showComments(customer);
    }

    function showMessage(e) {
        e.stopPropagation();
        props.showMessage(customer);
    }
}
