import React from 'react';

export default function SearchTerm(props) {

    const term = props.searchTerm;

    let title   = `Keyword: ${term.keyword}\n${term.status}: ${term.status_phrase}\nClicks: ${term.clicks} Costs: ${term.cost}`;
    let classes = `search-term status-${term.status} category-${term.categorized_as}`;

    let iconClasses = '';
    if (term.categorized_as === 'wanted') {
        iconClasses = 'fas categorized-as fa-thumbs-up';
    } else if (term.categorized_as === 'unwanted') {
        iconClasses = 'fas categorized-as fa-thumbs-down';

    }

    return (
        <div className={classes} title={title} onClick={edit}>
            {term.phrase}
            {iconClasses && <i className={iconClasses}/>}
        </div>
    );

    function edit(e) {

        e.stopPropagation();
        console.log(props);
        props.editPhrase('searchterm', props.searchTerm.phrase, props.customer);

    }
}
