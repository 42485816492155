import React, { useState, useEffect, useCallback } from 'react';
import * as $ from 'jquery';

import 'regenerator-runtime/runtime';
import Filters from './customers/Filters';
import Customer from './customers/Customer';
import PhraseForm from './customers/PhraseForm';
import CommentForm from './customers/CommentForm';
import MessageForm from './customers/MessageForm';
import AppContext from '../../contexts/AppContext';

export default function Customers() {

    const {api} = React.useContext(AppContext);

    const [agencies, setAgencies] = useState([]);

    const [customers, setCustomers]                 = useState([]);
    const [counts, setCounts]                       = useState({});
    const [filterVisibility, setFilterVisibility]   = useState(false);
    const [currentCustomerId, setCurrentCustomerId] = useState();
    const [currentPhrase, setCurrentPhrase]         = useState({});
    const [collapsed, setCollapsed]                 = useState(true);
    const [timeline, setTimeline]                   = useState(false);
    const [translate, setTranslate]                 = useState(false);

    const [options, setOptions] = useState({
            agency_id: '',
            sort:      'started_at',
            sort_dir:  -1,
            search:    '',
            filters:   [
                'active',
                'critical'
            ],
        }
    );

    let filterString = options.filters.join();

    const loadAgenciesCallback    = useCallback(loadAgencies, []);
    const reloadCustomersCallback = useCallback(
        reloadCustomers,
        [options.sort, filterString, options.agency_id, options.search]
    );

    useEffect(() => {
        loadAgenciesCallback();
    }, [loadAgenciesCallback]);


    useEffect(() => {
        reloadCustomersCallback();
    }, [reloadCustomersCallback, options.sort, filterString, options.agency_id, options.search]);

    return (

        <div className="container-fluid">

            <div className="filter-info"
                 style={{display: filterVisibility ? 'none' : 'block'}}
                 onClick={() => setFilterVisibility(true)}>

                {options.filters.join(', ')}

            </div>

            {filterVisibility && <Filters options={options}
                                          counts={counts}
                                          agencies={agencies}
                                          setOptions={setOptions}
                                          translate={translate}
                                          setTranslate={setTranslate}
                                          timeline={timeline}
                                          setTimeline={setTimeline}
                                          collapsed={collapsed}
                                          setCollapsed={setCollapsed}/>
            }


            <div id="count-box" className="text-right">
                {counts.total} customers, <span className="critical-text">{counts.critical}</span> is critical
            </div>

            <div>
                <div className="customer-container">
                    {customers.map((customer) => (
                        <Customer customer={customer}
                                  options={options}
                                  reloadCustomer={reloadCustomer}
                                  updateCustomer={updateCustomer}
                                  editPhrase={editPhrase}
                                  showComments={showComments}
                                  showMessage={showMessage}
                                  collapsed={collapsed}
                                  key={customer._id}
                        />
                    ))}
                </div>
            </div>

            <button className="btn btn-basic btn-block"
                    style={{display: (counts.unloaded > 0 ? 'block' : 'none')}}
                    onClick={() => {
                        reloadCustomers(true);
                    }}>Load More Customers
            </button>

            <PhraseForm
                phrase={currentPhrase.phrase}
                type={currentPhrase.type}
                customer={getCustomerById(currentCustomerId)}
                updateCustomer={updateCustomer}/>

            <CommentForm
                customer={getCustomerById(currentCustomerId)}
                updateCustomer={updateCustomer}/>

            <MessageForm
                customer={getCustomerById(currentCustomerId)}
                updateCustomer={updateCustomer}/>

        </div>
    );

    function reloadCustomers(append = false) {

        options.offset = append ? customers.length : 0;
        options.limit  = 20;

        return api.get('get-customers', options).then(res => {

            let newCustomers = append ? [...customers, ...res.data.customers] : res.data.customers;

            setCustomers(newCustomers);

            setCounts({
                unloaded: res.data.counts.total - newCustomers.length,
                ...res.data.counts,
            });

        });

    }

    function reloadCustomer(customer, sneaky = false) {

        let params = {
            agency_id:   customer.agency_id,
            customer_id: customer._id
        };

        api.get('get-fresh-customer', params, sneaky).then(res => updateCustomer(res.data));

    }

    function updateCustomer(customer) {

        setCustomers(customers.map(
            currentCustomer => currentCustomer._id === customer._id ?
                {...customer, collapsed: currentCustomer.collapsed} : currentCustomer
        ));

    }

    function loadAgencies() {

        return api.get('get-agencies', {}).then(res => setAgencies(res.data));

    }

    function editPhrase(type, phrase, customer) {

        setCurrentPhrase({type, phrase});
        setCurrentCustomerId(customer._id);

        $('#keyword-form').modal('show');

    }

    function showComments(customer) {

        setCurrentCustomerId(customer._id);

        $('#comment-form').modal('show');

    }

    function showMessage(customer) {

        setCurrentCustomerId(customer._id);

        $('#message-form').modal('show');

    }

    function getCustomerById(id) {

        let customer = null;

        customers.some(c => {
            if (c._id === id) {
                return customer = c;
            } else {
                return false;
            }
        });

        return customer;

    }
}
