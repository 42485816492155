import React, { useState } from 'react';

import 'regenerator-runtime/runtime';

import Modal, { ModalBody, ModalFooter } from './../../Modal';
import * as $ from 'jquery';

export default function KeywordForm(props) {

    let [languages, setLanguages] = useState('');
    let [phrase, setPhrase]       = useState('');

    return (

        <Modal title="Edit phrase" id="keyword-form">

            <ModalBody>

                <div className="form-group">

                    <input type="text" id="phrase" placeholder="Sökord" className="form-control" value={phrase}
                           onChange={(e) => setPhrase(e.target.value)}/>
                    <input type="text" id="languages" placeholder="Languages" className="form-control" value={languages}
                           onChange={(e) => setLanguages(e.target.value)}/>

                </div>

            </ModalBody>

            <ModalFooter>

                <div className="btn-group" role="group">

                    <button type="button" onClick={() => addKeyword('negative')} className="btn btn-danger">
                        + Negative
                    </button>

                    <button type="button" onClick={() => addKeyword('too_broad')} className="btn btn-warning">
                        + Too Broad
                    </button>

                    <button type="button" onClick={() => addKeyword('ignore')} className="btn btn-primary">
                        + Ignore
                    </button>

                    <button type="button" onClick={() => addKeyword('forbidden')} className="btn btn-danger">
                        <i className="fa fa-skull"/> Forbidden
                    </button>

                </div>

            </ModalFooter>
        </Modal>

    );

    function addKeyword(type) {

        props.saveKeyword(languages, phrase, 'shared_add_' + type).then(() => {

            setPhrase('');
            setLanguages('');

            $('#keyword-form').modal('hide');

        });
    }

}
