import 'regenerator-runtime/runtime';

import React, { useEffect, useState, useCallback } from 'react';
import AppContext from '../../contexts/AppContext';
import DatePicker from 'react-datepicker';
import moment from 'moment';

export default function Economy(props) {

    let initialDate = new Date();
    initialDate.setDate(1);

    const {api}           = React.useContext(AppContext);
    const [date, setDate] = useState(new Date().setDate(1));
    const [data, setData] = useState([]);

    const loadReport = useCallback(() => {

        api.get('get-economy-report', {date: moment(date).format('Y-M-DD')}).then(res => {
            setData(res.data);
        });

    }, [api, date]);

    useEffect(loadReport, [date]);

    return (
        <div className="container-fluid">
            <br/>
            <br/>
            <h2>Economy Report</h2>
            <br/>
            <div>
                <span>Date: </span>
                <DatePicker selected={date} onChange={date => setDate(date)} dateFormat="yyyy-MM-dd"/>
                <a href={process.env.REACT_APP_API_URL + '/get-economy-report?export=1&date=' + moment(date).format('Y-M-DD')}>
                    <button>Download</button>
                </a>
            </div>

            <br/>
            <br/>
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th>Agency</th>
                    <th>Balance SEK</th>
                    <th>Balance</th>
                    <th>Payed (Customer)</th>
                    <th>Payed (Agency)</th>
                    <th>Cost</th>
                    <th>Currency</th>
                </tr>
                </thead>
                <tbody>

                {data.map((row, index) => (
                    <tr key={index}>
                        <td>{row.agency}</td>
                        <td>{Math.round(row.sek_balance).toLocaleString()}</td>
                        <td>{row.balance.toLocaleString()}</td>
                        <td>{row.payed_customer.toLocaleString()}</td>
                        <td>{row.payed_agency.toLocaleString()}</td>
                        <td>{row.cost.toLocaleString()}</td>
                        <td>{row.currency}</td>
                    </tr>
                ))}

                </tbody>
            </table>
        </div>
    );

}
