import React, { useState, useEffect } from 'react';
import CustomFetch from '../../../CustomFetch';
import Modal, {ModalBody, ModalFooter} from '../../Modal';
import * as $ from 'jquery';

require('bootstrap');

export default function MessageForm({customer, updateCustomer}) {

    let [subject, setSubject] = useState('');
    let [message, setMessage] = useState('');
    let [lastEmailSent, setLastEmailSent] = useState('');

    useEffect(() => {

        if (customer && customer.alert_mail && customer.alert_mail.subject) {

            setSubject(customer.alert_mail.subject);
            setMessage(customer.alert_mail.message);
            setLastEmailSent('Last email sent by : ' + customer.alert_mail.user_id);
        }

    }, [customer]);

    return (
        <Modal id="message-form" title="Send Message">

            <ModalBody>
                <div className="form-group">

                    <label>
                        Subject
                        <input id="message_subject" type="text" placeholder="Subject" value={subject}
                               className="form-control" onChange={(e) => setSubject(e.target.value)}/>
                    </label>
                    <br/>
                    <label>
                        Message
                        <textarea id="message_body" className="form-control" value={message}
                                  onChange={(e) => setMessage(e.target.value)}/>
                    </label>
                    <span
                        id="mail_sent_by">{lastEmailSent}</span>
                    <br/>
                </div>
            </ModalBody>

            <ModalFooter>
                <button type="button" value="customer" className="btn btn-primary" onClick={sendMessage}>
                    <i className="fa fa-envelope"/> Email
                </button>
            </ModalFooter>

        </Modal>
    );

    function sendMessage(e) {

        e.stopPropagation();

        let data = {
            agency_id:    customer.agency_id,
            customer_id:  customer._id,
            mail_subject: subject,
            mail_body:    message,
        };

        CustomFetch.post('send-mail', data).then(res => {

            updateCustomer(res.data);
            $('#message-form').modal('hide');

        });

    }

}
