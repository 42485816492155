import 'regenerator-runtime/runtime';

import React, {useState, useEffect} from 'react';
import AppContext from '../../contexts/AppContext';
import moment from "moment";
import OverviewChart from "./util/OverviewChart";

export default function Overview() {
    const {api} = React.useContext(AppContext);

    let date = new Date();
    let firstDayOfThisMonth = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + 1;

    const [filters, setFilter] = useState({
        date: firstDayOfThisMonth,
    });

    const [overview, setOverview] = useState({
        dates: [],
        partners: [],
        monthly_summary: [],

    });

    useEffect(fetchOverview, [filters.date]);

    return (

        <div className="fluid container">
            <div className="m-5 pb-5">
                <OverviewChart items={overview}/>
            </div>

            <div className="filter-box">
                <select onChange={e => updateFilter({date: e.target.value})}>
                    {dates().map((date) => (
                        <option key={date} value={date}>{moment(date).format('MMMM, YYYY')}</option>
                    ))}
                </select>
            </div>
            <div className="fluid container">
                <section className="counts-section">
                    <h2 className="text-secondary">Per month</h2>
                    <div className="text-dark py-5">
                        <div className="container">

                            <div className="row">
                                <div className="col-lg-3 col-6 text-center customer-color">
                                    <div className="summary-box">
                                        <div data-toggle="counterUp">
                                            {overview.monthly_summary.active_customer || ''}
                                        </div>
                                        <div className={ 'trend ' + (overview.monthly_summary.diff_active_customer > 0 ? 'text-success' : 'text-danger')}>
                                            {overview.monthly_summary.diff_active_customer === 0 ? '' : (overview.monthly_summary.diff_active_customer > 0 ? '+'  : '') || ''}
                                            { overview.monthly_summary.diff_active_customer || ''}
                                        </div>
                                    </div>
                                    <h6>Total active customers</h6>
                                </div>
                                <div className="col-lg-3 col-6 text-center spend-color">
                                    <div className="summary-box">
                                        <div data-toggle="counterUp">{formatNumber(overview.monthly_summary.spend)}</div>
                                        <div className={ 'trend ' + (overview.monthly_summary.diff_spend > 0 ? 'text-success' : 'text-danger')}>
                                            {formatNumber(overview.monthly_summary.diff_spend) || ''}
                                        </div>
                                    </div>
                                    <h6>Total Spend</h6>
                                </div>
                                <div className="col-lg-3 col-6 text-center kick-color">
                                    <div className="summary-box">
                                        <div data-toggle="counterUp">{formatNumber(overview.monthly_summary.listing_kick)}</div>
                                        <div className={ 'trend ' + (overview.monthly_summary.diff_listings> 0 ? 'text-success' : 'text-danger')}>
                                            {formatNumber(overview.monthly_summary.diff_listings) || ''}
                                        </div>
                                    </div>
                                    <h6>Total Listings</h6>
                                </div>
                                <div className="col-lg-3 col-6 text-center kick-color">
                                    <div className="summary-box"
                                         title={'Ads: ' + overview.monthly_summary.ads_kick
                                             + '\n Listings: ' + overview.monthly_summary.listing_kick}>
                                        <div data-toggle="counterUp">{formatNumber(overview.monthly_summary.total_kick)}</div>
                                        <div className={ 'trend ' + (overview.monthly_summary.diff_kick > 0 ? 'text-success' : 'text-danger')}>
                                            {formatNumber(overview.monthly_summary.diff_kick) || ''}
                                        </div>
                                    </div>
                                    <h6>Total Kick</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <table className="paused table table-striped  table-bordered">
                    <thead>
                    <tr>
                        <th>Agency</th>
                        <th className="text-center">Active customers</th>
                        <th className="text-center">Spend</th>
                        <th className="text-center"></th>
                        <th className="text-center text">Listing Customers</th>
                        <th className="text-center text">Listing Kick</th>
                        <th className="text-center text">Kick</th>
                    </tr>
                    </thead>
                    <tbody>

                    {overview.partners.map((overview, index) => (
                        <tr key={index}>
                            <td>{overview.name}</td>
                            <td className="text-center">
                                <div style={{display: 'flex'}}>
                                    <div className="data">
                                        {overview.active_customer}
                                    </div>
                                    <div className="trend">
                                     <span className={ (overview.diff_active_customer > 0 ? 'text-success' : 'text-danger')}>
                                         <small>{overview.diff_active_customer === 0 ? '' : (overview.diff_active_customer > 0 ? '+'  : '') + overview.diff_active_customer}</small>
                                     </span>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center">
                                <div style={{display: 'flex'}}>
                                    <div className="data">
                                        {formatNumber(overview.spend)}
                                    </div>
                                    <div className="trend">
                                        <span className={ (overview.diff_spend > 0 ? 'text-success' : 'text-danger')}>
                                            <small>{(overview.diff_spend > 0 ? '+'  : '') + formatNumber(overview.diff_spend)}</small>
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center text-black-50">
                                    <small> {overview.card_cost > 0 ? '(Card cost: ' + formatNumber(overview.card_cost) + ')' : ''}</small>
                            </td>
                            <td className="text-center">
                                <div style={{display: 'flex'}}>
                                    <div className="data">
                                        {overview.listing_active_customer > 0 && overview.listing_active_customer}
                                    </div>
                                    <div className="trend">
                                        {overview.diff_listing !== 0 &&
                                            <span className={ (overview.diff_listing > 0 ? 'text-success' : 'text-danger')}>
                                                <small>{(overview.diff_listing > 0 ? '+'  : '') + formatNumber(overview.diff_listing)}</small>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </td>
                            <td className="text-center">
                                <small></small>
                            </td>
                            <td className="text-center">
                                <div style={{display: 'flex'}}>
                                    <div className="data">
                                        {formatNumber(overview.kick)}
                                    </div>
                                    <div className="trend">
                                        <span className={ (overview.diff_kick > 0 ? 'text-success' : 'text-danger')}>
                                            <small>{(overview.diff_spend > 0 ? '+'  : '') + formatNumber(overview.diff_kick)}</small>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );


    function fetchOverview() {
        api.get('get-overview', {date: filters.date}).then(res => setOverview(res.data));
    }

    function updateFilter(newFilter) {
        setFilter({...filters, ...newFilter});
    }
}

function dates() {
    let yearMonth = [];
    let date = new Date("2019-01-01");
    let end = new Date();

    while (date < end) {

        let dateToAdd = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + 1;
        date = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        yearMonth.push(dateToAdd);
    }

    return yearMonth.reverse();
}

function formatNumber(num) {
    if (num >= 1000000000 || num <= -1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'Mdkr';
    }
    if (num >= 1000000 || num <= -1000000) {
        return (num / 1000000).toFixed(2).replace(/\.0$/, '') + ' mkr';
    }
    if (num >= 1000 || num <= -1000) {
        return (num / 1000).toFixed(0).replace(/\.0$/, '') + ' tkr';
    }
    return (num || 0) + ' kr';
}
