import 'regenerator-runtime/runtime';

import React, { useState, useEffect } from 'react';
import AppContext from '../../contexts/AppContext';

export default function Status() {
    const {api} = React.useContext(AppContext);

    let [paused, setPaused] = useState([]);

    useEffect(fetchPaused, []);

    return (

        <div className="container-fluid">
            <h2>Latest </h2>
            <table className="paused table table-bordered">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Agency</th>
                    <th>Customer link</th>
                    <th>Status reason</th>
                </tr>
                </thead>
                <tbody>

                {paused.map((paused, index) => (

                    <tr key={index}>
                        <td className="text-strong">{paused.date}</td>
                        <td>{paused.agency_name}</td>
                        <td><a href={paused.link}>Link</a></td>
                        <td>{paused.status_reason}</td>
                    </tr>

                ))}
                </tbody>
            </table>
        </div>

    );

    function fetchPaused() {

        api.get('get-paused', {}).then(res => setPaused(res.data));

    }


}
