import React, { useCallback, useEffect, useRef } from 'react';
import { GoogleCharts } from 'google-charts';

export default function Chart(props) {

    const domObject = useRef();

    let stats = props.stats;

    const renderChart = useCallback(() => {

        if (stats && stats.graph && stats.graph.length > 1) {

            let data = new GoogleCharts.api.visualization.DataTable();

            data.addColumn('number', '');
            data.addColumn('number', 'Budget');
            data.addColumn('number', 'Cost');

            data.addRows(stats.graph);

            let options = {
                theme:               'maximized',
                legend:              {position: 'none'},
                axisTitlesPosition:  'none',
                pointsVisible:       false,
                enableInteractivity: false,

                hAxis:  {
                    baselineColor: '#ffffff',
                    textPosition:  'none',
                    textStyle:     {fontSize: 0},
                    gridlines:     {count: 0},
                },
                vAxis:  {
                    baselineColor: '#ffffff',
                    textPosition:  'none',
                    textStyle:     {fontSize: 0},
                    gridlines:     {count: 0},
                },
                series: {
                    0: {visibleInLegend: false, color: '#eeeeee'},
                    1: {visibleInLegend: false, color: '#007bff'},
                }
            };

            let chart = new GoogleCharts.api.visualization.LineChart(domObject.current);

            chart.draw(data, options);
        }
    }, [stats]);

    useEffect(() => {
        GoogleCharts.load(renderChart);
    }, [renderChart]);

    return (
        <div ref={domObject} className="sparkline"/>
    );

}

