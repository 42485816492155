import 'regenerator-runtime/runtime';

import React, { useState, useEffect } from 'react';
import AppContext from '../../contexts/AppContext';

export default function Transactions() {

    const {api} = React.useContext(AppContext);

    let [transactions, setTransactions] = useState([]);

    useEffect(fetchTransactions, []);

    return (

        <div className="container-fluid">
            <h2>Latest charges</h2>
            <table className="transactions table table-bordered">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Agency</th>
                    <th>Amount</th>
                    <th>Credits</th>
                    <th>Fee</th>
                    <th>Setup</th>
                    <th>VAT</th>
                    <th>Customer</th>
                    <th>Failure code</th>
                    <th>Stripe customer</th>
                </tr>
                </thead>
                <tbody>

                {transactions.map((transaction, index) => (

                    <tr key={index}>
                        <td className="text-strong">{transaction.date}</td>
                        <td>{transaction.agency_name}</td>
                        <td>{transaction.amount}</td>
                        <td>{transaction.credits}</td>
                        <td>{transaction.fee}</td>
                        <td>{transaction.setup}</td>
                        <td>{transaction.vat}</td>
                        <td>
                            <a target="_blank" rel="noopener noreferrer" href={transaction.admin_url}>
                                {transaction.customer_name}
                            </a>
                        </td>
                        <td>{transaction.failure_code}</td>
                        <td>{transaction.stripe_customer}</td>
                    </tr>

                ))}
                </tbody>
            </table>
        </div>

    );

    function fetchTransactions() {

        api.get('get-transactions', {}).then(res => setTransactions(res.data));

    }


}
