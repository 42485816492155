import React, { useCallback, useEffect, useRef } from 'react';
import { GoogleCharts } from 'google-charts';

export default function OverviewChart(props) {

    const domObject = useRef();

    let stats = props.items.dates;

    const renderChart = useCallback(() => {

        if (stats) {

            let data = new GoogleCharts.api.visualization.DataTable();

            data.addColumn('string', 'Date ');
            data.addColumn('number', 'Active Customers ');
            data.addColumn('number', 'Spend');
            data.addColumn('number', 'Kick');
            data.addColumn('number', 'Card cost');


            data.addRows(stats);

            let options = {
                theme:               'maximized',
                title: 'Last 12 months',
                smoothLine: true,
                curveType: 'function',
                legend: { position: 'bottom' },
                hAxis:  {
                    baselineColor: '#ffffff',
                    textPosition:  'bottom',
                    textStyle:     {fontSize: 10},
                    gridlines:     {count: 0},
                },
                vAxis:  {
                    baselineColor: '#ffffff',
                    textPosition:  'none',
                    textStyle:     {fontSize: 0},
                    gridlines:     {count: 0},
                    0: {
                        color: '#000000'
                    },
                    1: {

                    },
                    2: {

                    },
                    3: {

                    }
                },
                series:{
                    0:{targetAxisIndex:0, color: '#dc3545'},
                    1:{targetAxisIndex:1, color: '#ffc107'},
                    2:{targetAxisIndex:2, color: '#28a745'},
                    3:{targetAxisIndex:3, color: '#1a73e8'}
                }

            };

            let chart = new GoogleCharts.api.visualization.LineChart(domObject.current);

            chart.draw(data, options);
        }
    }, [stats]);

    useEffect(() => {
        GoogleCharts.load(renderChart);
    }, [renderChart]);

    return (
        <div ref={domObject} className="sparkline"/>
    );

}

