import React, { useState, useEffect } from 'react';
import CustomFetch from '../../../CustomFetch';
import Modal, { ModalBody } from '../../Modal';
import * as $ from 'jquery';

require('bootstrap');

export default function AgencyForm({agency}) {
console.log(agency);

    return (
        <Modal id="agency-form" title="View Agency">
            <ModalBody className="modal-body">
                <div id="agency_name"></div>
                <div id="google_ads_spend"></div>
                <div id="microsoft_ads_spend"></div>
                <div id="soconnect_cost"></div>
                <div id="gbp_cost"></div>

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <div>ek</div>


            </ModalBody>
        </Modal>
    );

}
