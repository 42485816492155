import 'regenerator-runtime/runtime';

import React, { useState, useEffect } from 'react';
import * as $ from 'jquery';
import AppContext from '../../contexts/AppContext';
import KeywordForm from './keywords/KeywordForm';

export default function Keywords() {

    const {api} = React.useContext(AppContext);

    let [keywords, setKeywords]  = useState([]);
    let [languages, setLanguage] = useState('');
    let [phrase, setPhrase]      = useState('');

    useEffect(fetchKeywords, []);

    return (

        <div className="container">
            <h2>Keywords</h2>
            <br/>
            <h4>Filter:</h4>
            <div className="filter-box">
                <input id="filter-phrase" placeholder="Filter Phrase" value={phrase}
                       onChange={(e) => setPhrase(e.target.value)}
                       onKeyPress={(e) => e.charCode === 13 && fetchKeywords()}
                />

                <input id="filter-language" placeholder="Filter Languages" value={languages}
                       onChange={(e) => setLanguage(e.target.value)}
                       onKeyPress={(e) => e.charCode === 13 && fetchKeywords()}
                />

                <button id="search-button" onClick={fetchKeywords}>Filter</button>
                <button className="float-right" onClick={showKeywordForm}>Add keyword</button>
            </div>

            <table className="phrases table table-bordered">
                <thead>
                <tr>
                    <th>Phrase</th>
                    <th>Category</th>
                    <th>Language</th>
                    <th>Customer</th>
                    <th>Actions</th>
                </tr>
                </thead>

                <tbody>

                {keywords.map((keyword, index) => (

                    <tr data-customer_id={keyword.customer_id} key={index}>
                        <td className="phrase">{keyword.phrase}</td>
                        <td className="category">{keyword.category}</td>
                        <td className="language">{keyword.language}</td>
                        <td className="customer">{keyword.customer}</td>
                        <td>
                            <button type="button" className="btn btn-danger"
                                    onClick={() => removeKeyword(keyword)}>Remove
                            </button>
                        </td>
                    </tr>

                ))}


                </tbody>
            </table>
            <KeywordForm saveKeyword={saveKeyword}/>

        </div>

    );

    function fetchKeywords() {

        let data = {language: languages, phrase};

        api.get('get-keywords', data).then(res => setKeywords(res.data));

    }

    function removeKeyword(keyword) {

        saveKeyword(
            keyword.language,
            keyword.phrase,
            'shared_remove',
            keyword.customer_id
        );
    }

    /**
     * Save keyword
     *
     * @param languages
     * @param phrase
     * @param action
     * @param customerId
     * @returns {*}
     */
    function saveKeyword(languages, phrase, action, customerId) {

        let data = {
            languages: languages,
            phrase:    phrase,
            action:    action,
        };

        if (customerId) {
            data.customer_id = customerId;
        }

        return api.post('save-keyword', data).then(() => {
            fetchKeywords();
        });

    }

    function showKeywordForm() {
        $('#keyword-form').modal('show');
    }

}
