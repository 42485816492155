import 'regenerator-runtime/runtime';

import React, { useState } from 'react';
import * as $ from 'jquery';
import Modal, {ModalBody} from '../../Modal';

export default function ChangesTable(props) {

    const [selectedRow, setSelectedRow] = useState(null);

    return (
        <div>
            <table id="changes" className="table table-bordered">
                <thead>
                <tr>
                    <th>Action</th>
                    <th>Model</th>
                    <th>User</th>
                    <th>Time</th>
                </tr>
                </thead>
                <tbody>
                {props.rows.map((row, index) => (
                    <tr key={index}>
                        <td>
                            <div className="show-details" onClick={() => showModal(row)}>{row.action}</div>
                        </td>
                        <td>{row.model_type}</td>
                        <td>{row.user_name}</td>
                        <td>{row.time}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            {selectedRow && ModalElement()}
        </div>

    );

    function ModalElement() {

        return (
            <Modal id="changes-modal" title="Log details">
                <ModalBody>
                    <div className="row mb-3">
                        <div className="col-md-3"><b>Action:</b>
                            <small id="changes-modal-action">{selectedRow.action}</small>
                        </div>
                        <div className="col-md-3"><b>Model:</b>
                            <small id="changes-modal-model">{selectedRow.model_type}</small>
                        </div>
                        <div className="col-md-3"><b>User:</b>
                            <small id="changes-modal-user">{selectedRow.user_name}</small>
                        </div>
                        <div className="col-md-3"><b>Time:</b>
                            <small id="changes-modal-time">{selectedRow.time}</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label><b>New version:</b></label>
                            <textarea id="new-area" className="details-textarea" value={selectedRow.new_preformatted}
                                      readOnly/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label><b>Old version:</b></label>
                            <textarea id="old-area" className="details-textarea" value={selectedRow.old_preformatted}
                                      readOnly/>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    function showModal(row) {

        setSelectedRow(row);

        $('#changes-modal').modal('show');

    }
}
