import React, { useState, useEffect } from 'react';
import CustomFetch from '../../../CustomFetch';
import Modal, { ModalBody, ModalFooter } from '../../Modal';
import * as $ from 'jquery';

require('bootstrap');

export default function PhraseForm(props) {

    let customer     = props.customer;
    let customerName = customer ? customer.name : '';

    let [languages, setLanguages] = useState('');
    let [phrase, setPhrase]       = useState('');
    let [isGlobal, setGlobal]     = useState(false);


    useEffect(() => {

        setGlobal(false);

        if (customer) {
            setLanguages(customer.languages);
        }

        setPhrase(props.phrase ?? '');

    }, [props.phrase, customer]);

    return (
        <Modal id="keyword-form" title={props.type === 'keyword' ? 'Keyword' : 'Searchterm'}>

            <ModalBody className="modal-body">
                <div className="form-group">

                    <input type="text" id="phrase" placeholder="Sökord" value={phrase}
                           className="form-control" onChange={e => setPhrase(e.target.value)}/>

                    <input type="text" id="languages" placeholder="Languages"
                           value={languages} className="form-control"
                           onChange={e => setLanguages(e.target.value)}/>

                    <div id="translations">
                        <input type="text" id="phrase_en" placeholder="Sökord"
                               value="" className="form-control" readOnly/>
                        <button type="button" value="add_negative"
                                className="btn btn-primary quick-translate">Translate
                        </button>
                    </div>

                    <div className="scope">
                        <div>
                            <label>
                                <input type="radio" name="global" value="0"
                                       onChange={e => changelGlobal(e, false)}
                                       checked={!isGlobal && 'checked'}/>
                                <span className="customer-name">{customerName}</span>
                            </label>
                        </div>
                        <div>
                            <label>
                                <input type="radio" name="global" value="1"
                                       onChange={e => changelGlobal(e, true)}
                                       checked={isGlobal && 'checked'}/>
                                <span>Global</span>
                            </label>
                        </div>
                    </div>

                </div>
            </ModalBody>

            <ModalFooter>

                <div className="actions">

                    {!isGlobal && props.type === 'keyword' && <KeywordActions/>}
                    {!isGlobal && props.type === 'searchterm' && <SearchTermActions/>}
                    {isGlobal && <GlobalActions/>}

                </div>
            </ModalFooter>

        </Modal>
    );

    function KeywordActions() {
        return (
            <div className="row keyword-actions">
                <div className="btn-group col-md-9 justify-content-start" role="group">

                    <ActionButton action="add_negative" button="btn btn-danger">+ Negative</ActionButton>
                    <ActionButton action="customer_add_too_broad" button="btn btn-warning">+ Too Broad</ActionButton>
                    <ActionButton action="customer_add_ignore" button="btn btn-primary">+ Ignore</ActionButton>
                    <ActionButton action="remove_keyword" button="btn btn-danger">- Delete</ActionButton>

                </div>
            </div>
        );
    }

    function SearchTermActions() {

        return (
            <div className="row search-term-actions">
                <div className="btn-group col-md-8 justify-content-start" role="group">
                    <ActionButton action="add_negative" button="btn btn-danger">+ Negative</ActionButton>
                    <ActionButton action="add_keyword" button="btn btn-primary">+ Keyword</ActionButton>
                </div>

                <div className="btn-group col-md-4 justify-content-end" role="group">
                    <ActionButton action="search_term_unwanted" button="btn btn-danger" icon="fas fa-thumbs-down"/>
                    <ActionButton action="search_term_wanted" button="btn btn-primary" icon="fas fa-thumbs-up"/>
                </div>
            </div>
        );
    }

    function GlobalActions() {

        return (
            <div className="row global-actions">
                <div className="btn-group col-md-12 justify-content-center" role="group">

                    <ActionButton action="shared_add_negative" button="btn btn-danger">+ Negative</ActionButton>
                    <ActionButton action="shared_add_too_broad" button="btn btn-warning">+ Too Broad</ActionButton>
                    <ActionButton action="shared_add_ignore" button="btn btn-primary">+ Ignore</ActionButton>
                    <ActionButton action="shared_add_forbidden" button="btn btn-danger" icon="fa fa-skull">
                        Forbidden
                    </ActionButton>

                </div>
            </div>
        );
    }

    function ActionButton(props) {
        return (
            <button type="button" className={props.button} onClick={e => saveKeywordForm(props.action, e)}>
                {props.icon && <i className={props.icon} />} {props.children}
            </button>
        );
    }

    function changelGlobal(e, value) {

        e.stopPropagation();

        setGlobal(value);
    }

    function saveKeywordForm(action, e) {

        e.stopPropagation();

        let data = {

            agency_id:   customer.agency_id,
            customer_id: customer._id,
            phrase:      phrase,
            languages:   languages.join(","),
            global:      isGlobal ? 1 : 0,
            action:      action

        };

        CustomFetch.post('save-keyword', data).then(res => {

            props.updateCustomer(res.data);

            $('#keyword-form').modal('hide');
        });

    }

}
