import 'regenerator-runtime/runtime';
import * as $ from 'jquery';
import React, { useState } from 'react';
import Modal, { ModalBody } from './../../Modal';

export default function GoogleAdsTable(props) {

    const [selectedRow, setSelectedRow] = useState(null);

    return (

        <div>

            <table id="google_ads" className="table table-bordered">
                <thead>
                <tr>
                    <th>Category</th>
                    <th>Service</th>
                    <th>Time</th>
                </tr>
                </thead>
                <tbody>
                {props.rows.map((row, index) => (

                    <tr key={index}>
                        <td>
                            <div className="show-details" onClick={() => showModal(row)}>
                                {row.request_function}
                            </div>
                        </td>
                        <td>{row.service}</td>
                        <td>{row.time}</td>
                    </tr>
                ))}


                </tbody>
            </table>

            {selectedRow && ModalElement()}

        </div>
    );

    function ModalElement(props) {
        return (
            <Modal title="Log details" id="ads-modal">
                <ModalBody>

                    <div className="row mb-3">
                        <div className="col-md-4"><b>Category:</b>
                            <small id="ads-modal-category">{selectedRow.request_function}</small>
                        </div>
                        <div className="col-md-4"><b>Service:</b>
                            <small id="ads-modal-service">{selectedRow.service}</small>
                        </div>
                        <div className="col-md-4"><b>Time:</b>
                            <small id="ads-modal-time">{selectedRow.time}</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <label><b>Request data:</b></label>
                            <textarea id="request-area" className="details-textarea" value={selectedRow.request_data}
                                      readOnly/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label><b>Responce data:</b></label>
                            <textarea id="response-area" className="details-textarea" value={selectedRow.response_data}
                                      readOnly/>
                        </div>
                    </div>
                </ModalBody>

            </Modal>
        );
    }

    function showModal(row) {

        setSelectedRow(row);

        $('#ads-modal').modal('show');

    }

}
