import React, { useState, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import AppContext from './../contexts/AppContext';
import CustomFetch from '../CustomFetch';
import Customers from './pages/Customers';
import Keywords from './pages/Keywords';
import Transactions from './pages/Transactions';
import Logs from './pages/Logs';
import Jobs from './pages/Jobs';
import Login from './pages/Login';
import Report from './pages/Report';
import Nav from './Nav';
import loading from './../../img/loading.gif';
import Economy from './pages/Economy';
import "react-datepicker/dist/react-datepicker.css";
import './../../scss/style.scss';
import Billing from './pages/Billing';
import Paused from './pages/Paused';
import Overview from './pages/Overview';

export default function App() {

    let routerRef = useRef();

    const [isLoading, setLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    CustomFetch.init(setLoading, isAuth => {

        setIsAuthenticated(isAuth);

        if(!isAuth) {
            routerRef.current.history.push('/');
        }

    });


    return (

        <Router ref={routerRef}>
            <AppContext.Provider value={{api: CustomFetch, setLoading}}>

                <div>

                    {isAuthenticated && <Nav />}

                    <Switch>
                        <Route path="/" exact component={Login}/>
                        <Route path="/customers" exact component={Customers}/>
                        <Route path="/keywords" exact component={Keywords}/>
                        <Route path="/transactions" exact component={Transactions}/>
                        <Route path="/logs" exact component={Logs}/>
                        <Route path="/jobs" exact component={Jobs}/>
                        <Route path="/economy" exact component={Economy}/>
                        <Route path="/billing" exact component={Billing}/>
                        <Route path="/paused" exact component={Paused}/>
                        <Route path="/overview" exact component={Overview}/>
                        <Route path="/report" exact component={Report}/>
                    </Switch>

                    <div id="loading" style={{display: isLoading ? 'block' : 'none'}}>
                        <img src={loading} alt="Loading..." />
                    </div>
                </div>
            </AppContext.Provider>
        </Router>
    );

}
