import 'regenerator-runtime/runtime';

import React, {useState, useEffect} from 'react';
import AppContext from '../../contexts/AppContext';
import moment from "moment";
import DataTable from 'react-data-table-component';
import AgencyForm from './agency/AgencyForm';
import * as $ from "jquery";

export default function Report() {

    const {api} = React.useContext(AppContext);

    let date = new Date();
    let firstDayOfThisMonth = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + 1;

    const [filters, setFilter] = useState({
        date: firstDayOfThisMonth,
    });

    const [data, setReport] = useState({
        dates: [],
        partners: [],
        monthly_summary: [],

    });

    useEffect(fetchOverview, [filters.date]);


    const ExpandedComponent = ({data}) => {
        const onRowClicked = (row, event)  => showAgency(row);

        return (

            <div className="container-fluid border border-dark">
                <div className="filter-box float-right">
                </div>
                <DataTable
                    columns={[
                        {
                            name: 'Agency',
                            selector: row => row.name,
                            sortable: true,
                        },
                        {
                            name: 'Active customers',
                            selector: row => row.active_customers,
                            sortable: true,
                            //format: row => formatNumber(row.ads_cost)
                        },
                        {
                            name: 'Deposits',
                            selector: row => row.deposits,
                            sortable: true,
                            format: row => <div><span title={formatCurrency(row.deposits)}>{formatNumber(row.deposits)}</span></div>
                        },
                        {
                            name: 'Ads',
                            selector: row => row.ads_cost,
                            sortable: true,
                            format: row => <div><span title={formatCurrency(row.ads_cost)}>{formatNumber(row.ads_cost)}</span></div>
                        },
                        {
                            name: 'Google',
                            selector: row => row.google_ads_cost,
                            sortable: true,
                            format: row => <div><span title={formatCurrency(row.google_ads_cost)}>{formatNumber(row.google_ads_cost)}</span></div>
                        },
                        {
                            name: 'Microsoft',
                            selector: row => row.microsoft_ads_cost,
                            sortable: true,
                            format: row => <div><span title={formatCurrency(row.microsoft_ads_cost)}>{formatNumber(row.microsoft_ads_cost)}</span></div>
                        },
                        {
                            name: 'Facebook',
                            selector: row => row.facebook_ads_cost,
                            sortable: true,
                            format: row => <div><span title={formatCurrency(row.facebook_ads_cost)}>{formatNumber(row.facebook_ads_cost)}</span></div>
                        },
                        {
                            name: 'Gbp',
                            selector: row => row.gbp_cost,
                            sortable: true,
                            format: row => <div><span title={formatCurrency(row.gbp_cost)}>{formatNumber(row.gbp_cost)}</span></div>
                        },
                        {
                            name: 'SoConnect',
                            selector: row => row.soconnect_cost,
                            sortable: true,
                            format: row => <div><span title={formatCurrency(row.soconnect_cost)}>{formatNumber(row.soconnect_cost)}</span></div>
                        },
                        {
                            name: 'CM',
                            selector: row => row.kick,
                            sortable: true,
                            format: row => <div><span title={formatCurrency(row.kick)}>{formatNumber(row.kick)}</span></div>
                        },
                    ]}
                    data={data.partners}
                    pagination
                    striped
                    fixedHeader
                    showAgency={showAgency}
                    onRowClicked={onRowClicked}
                />
                <AgencyForm

                    ></AgencyForm>
            </div>

        );
    }

    const columns = [
        {
            name: 'Month',
            selector: row => row.month,
            sortable: false,
        },
        {
            name: 'Active customers',
            selector: row => row.active_customers,
            sortable: true,
        },
        {
            name: 'Deposits',
            selector: row => row.deposits,
            sortable: true,
            format: row => <div><span title={formatCurrency(row.deposits)}>{formatNumber(row.deposits)}</span></div>
        },
        {
            name: 'Ads',
            selector: row => row.ads_cost,
            sortable: true,
            format: row => <div><span title={formatCurrency(row.ads_cost)}>{formatNumber(row.ads_cost)}</span></div>
        },
        {
            name: 'Google',
            selector: row => row.google_ads_cost,
            sortable: true,
            format: row => <div><span title={formatCurrency(row.google_ads_cost)}>{formatNumber(row.google_ads_cost)}</span></div>
        },
        {
            name: 'Microsoft',
            selector: row => row.microsoft_ads_cost,
            sortable: true,
            format: row => <div><span title={formatCurrency(row.microsoft_ads_cost)}>{formatNumber(row.microsoft_ads_cost)}</span></div>
        },
        {
            name: 'Facebook',
            selector: row => row.facebook_ads_cost,
            sortable: true,
            format: row => <div><span title={formatCurrency(row.facebook_ads_cost)}>{formatNumber(row.facebook_ads_cost)}</span></div>
        },
        {
            name: 'Gbp',
            selector: row => row.gbp_cost,
            sortable: true,
            format: row => <div><span title={formatCurrency(row.gbp_cost)}>{formatNumber(row.gbp_cost)}</span></div>
        },
        {
            name: 'SoConnect',
            selector: row => row.soconnect_cost,
            sortable: true,
            format: row => <div><span title={formatCurrency(row.soconnect_cost)}>{formatNumber(row.soconnect_cost)}</span></div>
        },
        {
            name: 'CM',
            selector: row => row.kick,
            sortable: true,
            format: row => <div><span title={formatCurrency(row.kick)}>{formatNumber(row.kick)}</span></div>
        },

    ];

    const conditionalRowStyles = [
        {
            when: row => row.getIsRowExpanded,
            style: {
                backgroundColor: "green",
                userSelect: "none"
            }
        }
    ];

    return (
        <div class="container-fluid ">
            <DataTable
                columns={columns}
                data={data}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                conditionalRowStyles={conditionalRowStyles}
            />
        </div>
    );

    function fetchOverview() {
        api.get('get-report', {date: filters.date}).then(res => setReport(res.data));
    }

    function formatNumber(num) {
        if (num >= 1000000000 || num <= -1000000000) {
            return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'Mdkr';
        }
        if (num >= 1000000 || num <= -1000000) {
            return (num / 1000000).toFixed(2).replace(/\.0$/, '') + ' mkr';
        }
        if (num >= 1000 || num <= -1000) {
            return (num / 1000).toFixed(0).replace(/\.0$/, '') + ' tkr';
        }
        return (num || 0) + ' kr';
    }

    function formatCurrency(value, decimals = 0) {
        /**
         * If we for some reason were passed a string we convert it. We can only
         * do this for strings since parseInt/parseFloat ONLY accepts strings,
         * anything else results in undefined behavior.
         */
        if (typeof value === 'string') {
            value = parseFloat(value);
        }

        if (typeof value !== 'number') {
        }

        return value.toFixed(decimals).toString()
            .replace('.', '.')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')  + ' kr';
    }

    function showAgency(row) {
        $('#agency_name').text(row.name);
        $('#agency-form').modal('show');
    }

};




