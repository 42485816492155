import React from "react";

export default function Ad(props) {

    let ad = props.ad;

    return (
        <div className="ad">
          <div className="title"><span className="ad-title">{ad.headline1} | {ad.headline2}</span></div>
          <div><a href={ad.url} target="_blank" rel="noopener noreferrer" className="display-url">{ad.url}</a></div>
          <div className="description1">{ad.description1}</div>
          <div className="description2">{ad.description2}</div>
        </div>
    );

}
