import React from 'react';

export default function Modal(props) {

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-labelledby=""
             aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">

                    <ModalHeader title={props.title}/>

                    {props.children}

                </div>
            </div>
        </div>
    );

}

export function ModalHeader(props) {

    return (
        <div className="modal-header">
            <h5 className="modal-title">{props.title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    );
}

export function ModalBody(props) {

        return (
            <div className="modal-body">
                {props.children}
            </div>
        );

}

export function ModalFooter(props) {

    return (
        <div className="modal-footer justify-content-end actions">
            {props.children}
        </div>
    );
}

