import React, { useState } from 'react';

export default function YesNo(props) {

    const name              = props.name;
    const [value, setValue] = useState(props.value);

    return (
        <div>
            <h4>{props.title}</h4>
            <div className="btn-group">

                <div className={value ? 'btn active ' + name : 'btn ' + name}
                     onClick={() => onClick(true)}>Yes
                </div>
                <div className={!value ? 'btn active ' + name : 'btn ' + name}
                     onClick={() => onClick(false)}>No
                </div>

            </div>
        </div>
    );

    function onClick(newValue) {

        setValue(newValue);

        props.onChange(newValue);

    }
}
