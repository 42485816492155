import React from 'react';

export default function Timeline(props) {

    let endStamp     = (new Date()).getTime();
    let startStamp   = endStamp - (86400 * 1000 * 50);
    let totalPercent = 0;

    return (
        <div className="col-lg-12 timeline">
            {getActivityList(props.timeline).map(activity => (
                    <div style={{width: activity.width}} key={activity.id}>

                        {props.options.timeline ?
                            <i title={activity.title} style={{color: activity.color}} className={activity.icon} />
                            :
                            <div title={activity.title} />

                        }
                    </div>
                )
            )}

        </div>
    );

    function getActivityList(timeline) {

        let activityList = [];

        if (timeline) {

            timeline.forEach((activity, index) => {
                if (activity.when * 1000 > startStamp) {

                    let percent  = Math.round(((activity.when * 1000 - startStamp) / (endStamp - startStamp)) * 100);
                    let width    = percent - totalPercent;
                    let whenDate = new Date(activity.when * 1000);

                    let title = activity.what + ' - ' + activity.who + '\n' + whenDate.toLocaleDateString() + '\n' + (activity.info || '');
                    let color = activity.role === 'customer' ? '#aa0000' : (activity.role === 'manager' ? '#00aa00' : '#000000');
                    let icon  = activity.what === 'okayed' ? 'fas fa-check' : (activity.what === 'changed' ? 'fas fa-pen' : 'far fa-eye');

                    activityList.push({
                        id:    index,
                        width: width + '%',
                        title: title,
                        color: color,
                        icon:  icon,
                    });

                    totalPercent += width;

                }
            });

        }

        return activityList;
    }
}
