import 'regenerator-runtime/runtime';

import React, { useEffect, useState } from 'react';
import AppContext from '../../contexts/AppContext';
import DatePicker from 'react-datepicker';
import moment from 'moment';

export default function Economy(props) {

    let initialDate = new Date();
    initialDate.setDate(1);

    const {api}           = React.useContext(AppContext);

    const [startDate, setStartDate] = useState(new Date().setDate(1));
    const [endDate, setEndDate] = useState(new Date().setDate(1));
    const [data, setData] = useState([]);

    useEffect(() => {

        let params = {
            start_date: moment(startDate).format('Y-M-DD') ,
            end_date: moment(endDate).format('Y-M-DD') ,
        };

        api.get('get-billing-report', params).then(res => {
            setData(res.data);
        });

    }, [startDate, endDate, api]);

    return (
        <div className="container-fluid">
            <br/>
            <br/>
            <h2>Economy Report</h2>
            <br/>
            <div>
                <span>Date: </span>
                <DatePicker selected={startDate} onChange={date => setStartDate(date)} dateFormat="yyyy-MM-dd"/>
                <DatePicker selected={endDate} onChange={date => setEndDate(date)} dateFormat="yyyy-MM-dd"/>

            </div>

            <br/>
            <br/>
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th>Agency</th>
                    <th>Cost SEK</th>
                    <th>Fee SEK</th>
                    <th>Earn</th>
                    <th>Earn By Min</th>
                    <th>Earn By Spend</th>
                    <th>Earn By Fee</th>
                </tr>
                </thead>
                <tbody>

                {data.map((row, index) => (
                    <tr key={index}>
                        <td>{row.name}</td>
                        <td>{Math.round(row.cost)}</td>
                        <td>{Math.round(row.fee)}</td>
                        <td>{Math.round(row.earn)}</td>
                        <td>{Math.round(row.earn_by_minimum)}</td>
                        <td>{Math.round(row.earn_by_spend)}</td>
                        <td>{Math.round(row.earn_by_fee)}</td>
                    </tr>
                ))}

                </tbody>
            </table>
        </div>
    );

}
