import 'regenerator-runtime/runtime';

import React, { useState } from 'react';
import AppContext from '../../contexts/AppContext';
import { loadAuth2 } from 'gapi-script';
import signInImg from './../../../img/google_sign_in.png';

export default function Login(props) {

    const {api} = React.useContext(AppContext);
    let auth2   = null;

    let clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    const [isLoaded, setIsLoaded] = useState(false);


    loadAuth2(clientId).then(res => {
        auth2 = res;
        setIsLoaded(true);
    });

    return (

        <div className="login">

            <div className="login-form">

                {isLoaded && <div id="sign-in" onClick={signIn}>
                    <img src={signInImg} alt="Sign In"/>
                </div>}

            </div>

        </div>

    );

    function signIn() {

        auth2.grantOfflineAccess().then(response => {

            return api.get('login', {
                code:         response.code,
                redirect_uri: window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
            }).then(() => {
                props.history.push('/overview');
            });

        });

    }

}
